import {getDataObject, getDBDataObject, getVersionsData} from "o365.modules.VersionsDataObject.ts";
import API from 'o365.modules.data.api.ts';
import VersionsSourceProvider from 'o365.modules.VersionSourceProvider.ts';

interface IFileVersion{
    fileName:string,
    code:string
}
interface IDiffFile{
    fileName:string,
    code1:string,
    code2:string,
    added:boolean,
    removed:boolean,
    modified:boolean,
    hasChanges:boolean,
}
const versionsObject = new Map();

export function getVersionsCompare(pFileName:string, pType:string = "files",pCurrentVersion:number = 0){
    if(!versionsObject.has(pFileName)){
        versionsObject.set(pFileName,new VersionsCompare(pFileName,pType, pCurrentVersion));
    }
    return versionsObject.get(pFileName);

}
export default class VersionsCompare{
    fileName:string;
    type:string = "files";
    currentVersion:number;
    dsSource1:any;
    dsSource2:any;
    versionsData:Map<string,string> = new Map();
    files:Map<number,string> = new Map();
    appFiles:Map<number,Array<IFileVersion>> = new Map();
    isLoading:boolean=false;
    versionSourceProvider:VersionsSourceProvider;
    dsInitiated:boolean = false;

    get source1(){
        return this.dsSource1.current;
    }

    get source2(){
        return this.dsSource2.current;
    }

    
    constructor(pFileName:string, pType:string = "files", pCurrentVersion:number = 0){
        this.fileName = pFileName;
        this.type = pType;
        this.currentVersion = parseInt(pCurrentVersion.toString());

        if(this.type === 'DBObject'){
            this.dsSource1 = getDBDataObject(this.fileName,"_src1");
            this.dsSource2 = getDBDataObject(this.fileName,"_src2");
        } else {

            this.dsSource1 = getDataObject(this.fileName,"_src1");
            this.dsSource2 = getDataObject(this.fileName,"_src2");
        
            this.versionSourceProvider = new VersionsSourceProvider(pFileName,pType);
        }
    }

    async initDataObjects(){

        if(this.dsInitiated) return;
        this.dsSource1.state.isLoading = true;
        this.dsSource2.state.isLoading = true;
        
        const vDevelopment = await this.getDevelopmentVersion();
        let vVersionData;
        let vVersionData2;

        if(this.type !== 'DBObject') {
            vVersionData = await getVersionsData(this.fileName,this.type, this.currentVersion);
            vVersionData2 = vVersionData.data.map((x:any) => x.item ? x.item : x);
        }else{
            this.dsSource1.recordSource.whereClause = `DBObjectID = '${this.fileName}'`;
             this.dsSource2.recordSource.whereClause = `DBObjectID = '${this.fileName}'`;
            try{
                await this.dsSource1.load();
                await this.dsSource2.load(); 
            }catch{

            }finally{
                 
                vVersionData = this.dsSource1;
                console.log(vVersionData.data.length)
                vVersionData2 = vVersionData.data.map((x:any) => x.item ? x.item : x);
            }
        }

        if(vDevelopment?.length && this.type === "files"){
            vVersionData.data.push({
                Version:9999999999,
                RowKey:this.fileName+".TestContent",
                PartitionKey:this.fileName,
                PublishDescription:"Current Test Content",
                Timestamp:vDevelopment[0].Updated,
                CreatedBy:vDevelopment[0].UpdatedBy,
            })
            if(vDevelopment[0].Content !== vDevelopment[0].ContentTest){

         
                vVersionData2.push({
                    Version:9999999998,
                    PartitionKey:this.fileName,
                    RowKey:this.fileName+".Content",
                    PublishDescription:"Current Content",
                    Timestamp:vDevelopment[0].Updated,
                    CreatedBy:vDevelopment[0].UpdatedBy,
                });
                this.files.set(9999999998,vDevelopment[0].Content);
            }
            this.files.set(9999999999,vDevelopment[0].ContentTest);
            
        }

        if(vDevelopment?.length && this.type === "template") {
                vVersionData.data.push({
                    Version:9999999999,
                    RowKey:this.fileName+".TestContent",
                    PartitionKey:this.fileName,
                    PublishDescription:"Current Test Content",
                    Timestamp:vDevelopment[0].Updated,
                    CreatedBy:vDevelopment[0].UpdatedBy,
                })
            if(vDevelopment[0].HTMLTemplateTest !== vDevelopment[0].HTMLTemplate){
                vVersionData2.push({
                    Version:9999999998,
                    PartitionKey:this.fileName,
                    RowKey:this.fileName+".Content",
                    PublishDescription:"Current Content",
                    Timestamp:vDevelopment[0].Updated,
                    CreatedBy:vDevelopment[0].UpdatedBy,
                });
                this.files.set(9999999998,vDevelopment[0].HTMLTemplate);
            }
            this.files.set(9999999999,vDevelopment[0].HTMLTemplateTest);
        }
    

        if(vDevelopment?.hasOwnProperty("app") && this.type === "apps"){
            vVersionData.data.push(vDevelopment.app);
            this.appFiles.set(vDevelopment.app.Version,vDevelopment.files);
        }

        this.dsSource1.enableClientSideHandler(vVersionData2);
        this.dsSource2.enableClientSideHandler(vVersionData.data.map((x: any) => x.item ? x.item : x));
  

          this.dsSource1.filterObject.updateItem({
            column:'Version',
            operator:'lessthan',
            value: this.type === 'DBObject' ? vVersionData.data[0].Version : vVersionData.data[vVersionData.data.length-1].Version
        });

        this.dsSource1.filterObject.apply();
        this.dsSource2.load();
        this.dsSource1.state.isLoading = false;
        this.dsSource2.state.isLoading = false;

        this.dsInitiated = true;
        
    }


    async getFileData(pRow:any){

        if(this.type == "files"){
            let vData = this.files.get(pRow.Version);
            if(vData && typeof vData === "string"){
                return vData;
            }
            vData = await this.versionSourceProvider.getSourceData(pRow.Location);
            this.files.set(pRow.Version,vData['Content']??vData);
           
            return this.files.get(pRow.Version);
        }
        if(this.type == "template"){
            let vData = this.files.get(pRow.Version);
            if(vData && typeof vData === "string"){
                return vData;
            }
            vData = await this.versionSourceProvider.getTemplateSourceData(pRow.ID, pRow.Version);
            this.files.set(pRow.Version,vData['Html']??vData);

            const vReturn = this.files.get(pRow.Version)
            return vReturn?.toString().replace(/^`|`$/g, '')
        }

        return null;//some other file

         
    }

    async getAppFiles(pRow:any){
        if(!this.appFiles.has(pRow.Version)){
            const vData = await this.versionSourceProvider.getSourceData(pRow.Location);
            this.appFiles.set(pRow.Version,this._parseAppsFilesFromAzure(vData));
       
        }

        return this.appFiles.get(pRow.Version);
    }

    async getCurrentAppFiles(){
        const vSource1 = await this.getAppFiles(this.source1);
        const vSource2 = await this.getAppFiles(this.source2);

        return this._mergeAndReturnDiffs(vSource1,vSource2);
    }


    private _mergeAndReturnDiffs(pSource1:Array<any>,pSource2:Array<any>){
        const vReturn:Array<IDiffFile> = [];
        pSource1.forEach(file=>{
            const vFoundIndex = pSource2.findIndex(x=>x.fileName === file.fileName);
            const vSource2Row = vFoundIndex > -1?pSource2[vFoundIndex]:null;
            const vTmp:IDiffFile = {
                fileName:file.fileName,
                added:vSource2Row?false:true,
                code1:file.code,
                code2:vSource2Row?vSource2Row.code:null,
                modified:false,
                hasChanges:false,
                removed:false
            }
            vTmp.modified = vTmp.code1 !== vTmp.code2;
            vTmp.hasChanges = vTmp.modified || vTmp.added;
 

            vReturn.push(vTmp);
         
        });

        pSource2.forEach((file:IFileVersion)=>{
            const vFoundIndex = pSource1.findIndex(x=>x.fileName === file.fileName);
            if(vFoundIndex === -1)
            vReturn.push({
                fileName:file.fileName,
                code1:null,
                code2:file.code,
                modified:false,
                hasChanges:true,
                added:false,
                removed:true
            })
            //addf files which where removed fro that version

        })

        return vReturn;
    }
  

    async getCurrentSourceData(){

        const vReturn = {source1:{},source2:{}};
        vReturn["source1"] = {
            fileName:this.source1.PartitionKey,
            id:"src1"+this.source1.RowKey,
            code:await this.getFileData(this.source1)
        }
        vReturn["source2"] = {
            fileName:this.source2.PartitionKey,
            id:"src2"+this.source2.RowKey,
            code:await this.getFileData(this.source2)
        }

        return vReturn;
    }

    getCurrentDBSourceData(){       
        const vReturn = {
            source1:{
                fileName:this.source1.DBObjectID,
                id:"src1"+this.source1.PrimKey,
                code: this.source1.TSQLCommand
            },
            source2:{
                fileName:this.source2.DBObjectID,
                id:"src2"+this.source2.PrimKey,
                code: this.source2.TSQLCommand
            }
        };
        return vReturn
    }

    async getDevelopmentVersion(){
        if(this.type === "files"){
            return API.requestPost('/api/data',JSON.stringify({
                operation:"retrieve",
                viewName:"sviw_O365_StaticFiles",
                whereClause:`[ID] = '${this.fileName}' AND ISNULL([ContentTest],'') <> ''`,
                fields:[{name:"ContentTest"},{name:"Content"},{name:"UpdatedBy"},{name:"Updated",type:"datetime"}]
            }))
        }

        if(this.type === "template"){
            return API.requestPost('/api/data',JSON.stringify({
                operation:"retrieve",
                viewName:"sviw_O365_Templates",
                whereClause:`[ID] = '${this.fileName}' AND ISNULL([HTMLTemplateTest],'') <> ''`,
                fields:[{name:"HTMLTemplateTest"},{name:"HTMLTemplate"},{name:"UpdatedBy"},{name:"Updated",type:"datetime"}]
            }))
        }
           

        if(this.type === "apps"){
            
             const vApp = await API.requestPost('/api/data',JSON.stringify({
                operation:"retrieve",
                viewName:"sviw_O365_Apps",
                whereClause:`[ID] = '${this.fileName}' `,
                fields:[{name:"HTMLTest"},{name:"Authenticated"}/*,{name:"Template_ID"}*/,{name:"Namespace_ID"},{name:"Config"},{name:"Type"},{name:"NoTemplate"},
                    {name:"DataObjectsJson"},{name:"Title"},{name:"TwoFactorRequired"},{name:"AllowEveryone"},{name:"DeveloperRequired"},
                    {name:"UpdatedBy"},{name:"Updated",type:"datetime"}]//{name:"MenuGroup_ID"},{name:"Parent_ID"},
            }))
            const vAppFiles = await API.requestPost('/api/data',JSON.stringify({
                operation:"retrieve",
                viewName:"sviw_O365_AppsFiles",
                whereClause:`[App_ID] = '${this.fileName}' `,
                fields:[{name:"ID",alias:"fileName"},{name:"ContentTest",alias:"code"}]
            }))

            return {
                app:{
                    Version:9999999999,
                    RowKey:this.fileName+".Development",
                    PartitionKey:this.fileName,
                    PublishDescription:"Current Development",
                    Timestamp:vApp[0].Updated,
                    CreatedBy:vApp[0].UpdatedBy},
                files:this._parseAppsFilesFromDB(vApp[0],vAppFiles)
            }
        }
  
    } 

    private _parseAppsFilesFromDB(pAppRow:any,pAppFiles:Array<any>){
        const vReturn = [];
        vReturn.push({
            fileName:"HTML.html",
            code:pAppRow.HTMLTest
        })
        vReturn.push({
            fileName:"Settings.json",
            code:this._parseAppSettings(pAppRow)
        });
        pAppFiles.forEach(file=>{
            vReturn.push(file.Content??file);
        })

        
        try{
            
            if (pAppRow.DataObjectsJson) {
                JSON.parse(pAppRow.DataObjectsJson).forEach((ds: any) => {
                    const fixedDs = this._fixFieldsPropertiesValues(ds)
                    vReturn.push({
                        fileName: ds.id + ".json",
                        code: this._addDefaultSettingsToDataObjects(fixedDs)
                    });
                });
            }
          
        }catch{
              //failed to parse Data Object json
              console.warn("failed to parse");
        }

   
           if(pAppRow.Config){
                vReturn.push({
                    fileName:"Config.json",
                    code:pAppRow.Config
                });
            }
       
        return vReturn;
    }

    private _fixFieldsPropertiesValues(pDataObjectJson:any){

        pDataObjectJson.fields.forEach((field: any)=>{
            field.sortOrder = JSON.parse(field.sortOrder);
            field.groupByOrder = JSON.parse(field.groupByOrder);

        })

        return pDataObjectJson;

    }

    private _addDefaultSettingsToDataObjects(pDataObjectJson:any){
      //  pDataObjectJson.forEach((ds:any)=>{
            if(pDataObjectJson.hasOwnProperty('fields') && pDataObjectJson.fields){
                pDataObjectJson.fields.forEach((field:any,index:number)=>{
                    if(!field.hasOwnProperty('type')){
                        field['type'] = null;
                    }
                    pDataObjectJson.fields[index] = this._oderJsonSettings(field);
                   
                })
                
            }

     
            

            if(!pDataObjectJson.hasOwnProperty('disableAutoLoad')){
                pDataObjectJson['disableAutoLoad'] = false;
            }
            if(!pDataObjectJson.hasOwnProperty('optimisticLocking')){
                pDataObjectJson['optimisticLocking'] = false;
            }
            /*
            "disableAutoLoad": false,
	"optimisticLocking": false
            */

        //})

        const datasources  = JSON.stringify(pDataObjectJson);
       
     
        return datasources;
    }

    private _parseAppsFilesFromAzure(pAppRow:any){
        if(pAppRow.Content){
            try{
                pAppRow = JSON.parse(pAppRow.Content);
            }catch{
                console.log("failed to parse app row",pAppRow)
                pAppRow = pAppRow.Content;
            }
        }
        const vReturn = [];
        vReturn.push({
            fileName:"HTML.html",
            code:pAppRow.Html
        })
        vReturn.push({
            fileName:"Settings.json",
            code:this._parseAppSettings(pAppRow)
        });
        pAppRow.AppsFiles.forEach((file:any)=>{
            vReturn.push({
                fileName:file.Name,
                code:file.Content
            });
        })

        

        if(pAppRow.DataObjects)
        pAppRow.DataObjects.forEach((ds:any)=>{
                vReturn.push({
                fileName:ds.id+".json",
                code:this._addDefaultSettingsToDataObjects(ds)
            });
        })

   
        if(pAppRow.ConfigJson){
            vReturn.push({
                fileName:"Config.json",
                code:pAppRow.ConfigJson
            });
        }
       
        return vReturn;
    }

    private _parseAppSettings(pAppRow:any){
        const vSettingsFields = [ 'Authenticated', 'Template_ID', 'Type', 'Title', 'TwoFactorRequired', 'AllowEveryone', 'DeveloperRequired', 'NoTemplate', 'MenuGroup_ID', 'Parent_ID','Type','MenuGroup_ID'];
       // const vFieldsToIgnore = ['HTMLTest','Updated','PrimKey','AppsFiles','Id','Version','Html',"CreatedBy","UpdatedById","ConfigJson","DataObjects","Files","UpdatedBy","DataObjectsJson","Config","Namespace_ID"]
        const vReturn = {};
       /* Object.keys(pAppRow).forEach((key:string)=>{
            if(vFieldsToIgnore.indexOf(key) == -1){
                vReturn[key] = pAppRow[key];
            }
        })*/
        vSettingsFields.forEach((field:any)=>{
            vReturn[field] = pAppRow[field];
        })
        try{
            return JSON.stringify(this._oderJsonSettings(vReturn));
        }catch{
            console.warn("Faile to parse app settings");
            return {};
        }
    }

    private _oderJsonSettings(pJson:any){
        const ordered = Object.keys(pJson).sort().reduce(
        (obj:any, key:any) => { 
            obj[key] = pJson[key]; 
            return obj;
        }, 
        {}
        );
        return ordered;
    }
}

